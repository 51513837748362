import axios from 'axios';
import Cookies from 'js-cookie';


// Categorías


export const updateCategoryAction = async (category) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('access_token')}`
        }
    }

    const response = await axios.put(`/put/${category.id}/`, category, config)

    if (response.data.authenticated) {
        return response.data
    }
}


export const deleteCategoryAction = async (id) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` };
    const response = await axios.delete(`/delete/${id}`, { headers, withCredentials: true });
    if (response.data.authenticated) {
        return response.data
    }
}


export const categoryActionDetails = async (id) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` }
    const response = await axios.get(`/get/${id}/`, { headers, withCredentials: true });

    if (response.data.authenticated) {
        return response.data
    }
}


export const createCategoryAction = async (nombre_categoria, descripcion, visibilidad, imagen) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access_token')}`
    };
    try {
        const response = await axios.post(`/post/`, { 'nombre': nombre_categoria, 'descripcion': descripcion, 'visibilidad': visibilidad, 'imagen': imagen }, { headers, withCredentials: true });
        if (response.data.authenticated) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error('Categoría no encontrada');
        }
    }
}


export const listCategories = async () => {
    const response = await axios.get(`/get/`, { withCredentials: true });
    if (response.data.authenticated) {
        return response.data.categories
    }
}


export const listCategoriesAll = async () => {
    const response = await axios.get(`/get-all/`);
    if (response.data.categories) {
        return response.data.categories;
    }
}


export const checkAuth = async () => {
    const response = await axios.get(`/users/check-auth/`, { withCredentials: true });
    if (response.data.authenticated) {
        return true;
    }
}


export const handleDragEndCategories = async (draggableId, sourceIndex, destinationIndex) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` };
    const response = axios.post(`/update_order/`, { id: draggableId, sourceIndex: sourceIndex, destinationIndex: destinationIndex }, { headers, withCredentials: true })

    if (response.data.authenticated) {
        return response.data
    }
}


// Secciones


export const createSeccionAction = async (nombre, descripcion, idCategoria, visibilidad, imagen) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access_token')}`
    };
    const response = await axios.post(`/post-seccion/${idCategoria}/`, { 'nombre': nombre, 'descripcion': descripcion, 'visibilidad': visibilidad, 'imagen': imagen }, { headers, withCredentials: true });
    if (response.data.authenticated) {
        return response.data
    }
}


export const seccionActionDetails = async (id) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` }
    const response = await axios.get(`/get-seccion/${id}/`, { headers, withCredentials: true });

    if (response.data.authenticated) {
        return response.data
    }
}


export const updateSeccionAction = async (seccion) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('access_token')}`
        }
    }

    const response = await axios.put(`/put-seccion/${seccion.id}/`, seccion, config)

    if (response.data.authenticated) {
        return response.data
    }
}


export const deleteSeccionAction = async (id) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` };
    const response = await axios.delete(`/delete-seccion/${id}/`, { headers, withCredentials: true });

    return response.data;
}


export const listSecciones = async (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access_token')}`
    };
    const response = await axios.get(`/get-secciones/${id}`, { headers, withCredentials: true });
    if (response.data.authenticated) {
        return response.data.secciones
    }
}


export const getMenu = async (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access_token')}`
    };
    const response = await axios.get(`/get-seccion-menu/`, { headers, withCredentials: true });
    if (response.data.authenticated) {
        return response.data.exist
    }
}


export const handleDragEndSecciones = async (draggableId, sourceIndex, destinationIndex) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` };
    const response = await axios.put(`/update-order-secciones/`, { id: draggableId, sourceIndex: sourceIndex, destinationIndex: destinationIndex }, { headers, withCredentials: true })

    if (response.data.authenticated) {
        return response.data
    }
}


// Productos


export const listProductos = async (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access_token')}`
    };
    const response = await axios.get(`/get-productos/${id}`, { headers, withCredentials: true });
    if (response.data.authenticated) {
        return response.data.productos
    }
}


export const productoActionDetails = async (id) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` }
    const response = await axios.get(`/get-producto/${id}/`, { headers, withCredentials: true });

    if (response.data.authenticated) {
        return response.data
    }
}


export const createProductAction = async (nombre, descripcion, idSeccion, precio, opciones, visibilidad, imagen) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access_token')}`
    };
    const response = await axios.post(`/post-producto/${idSeccion}/`, { 'nombre': nombre, 'descripcion': descripcion, 'precio': precio, 'imagen': imagen, 'opciones': opciones, 'visibilidad': visibilidad }, { headers, withCredentials: true });
    if (response.data.authenticated) {
        return response.data
    }
}


export const updateProductoAction = async (producto) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('access_token')}`
        }
    }


    const response = await axios.put(`/put-producto/${producto.id}/`, producto, config)

    if (response.data.authenticated) {
        return response.data
    }
}


export const deleteProductoAction = async (id) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` };
    const response = await axios.delete(`/delete-producto/${id}/`, { headers, withCredentials: true });

    return response.data;
}


export const handleDragEndProductos = async (draggableId, sourceIndex, destinationIndex) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` };
    const response = axios.post(`/update-order-productos/`, { id: draggableId, sourceIndex: sourceIndex, destinationIndex: destinationIndex }, { headers, withCredentials: true })

    if (response.data.authenticated) {
        return response.data
    }
}


// Opciones


export const OpcionesActionDetails = async () => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` }
    const response = await axios.get(`/get-opciones/`, { headers, withCredentials: true });

    if (response.data.authenticated) {
        return response.data.opciones
    }
}


export const createOpcionesAction = async (nombre, opciones) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access_token')}`
    };
    const response = await axios.post(`/post-opcion/`, { 'nombre': nombre, 'opciones': opciones }, { headers, withCredentials: true });
    if (response.data.authenticated) {
        return response.data
    }
}


export const deleteOpcionAction = async (id) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` };

    const response = await axios.delete(`/delete-opcion/${id}/`, { headers, withCredentials: true });

    if (response.data.authenticated) {
        return response.data
    }
}


// Opcion Sola


export const listOpcionesSolas = async () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access_token')}`
    };
    const response = await axios.get(`/get-opciones-solas/`, { headers, withCredentials: true });
    if (response.data.authenticated) {
        return response.data.opciones
    }
}


export const createOpcionSolaAction = async (nombre, precio) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access_token')}`
    };
    const response = await axios.post(`/post-opcion-sola/`, { 'nombre': nombre, 'precio': precio }, { headers, withCredentials: true });
    if (response.data.authenticated) {
        return response.data
    }
}


export const updateOpcionSolaAction = async (opcion) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('access_token')}`
        }
    }

    const response = await axios.put(`/put-opcion-sola/${opcion.id}/`, opcion, config)

    if (response.data.authenticated) {
        return response.data
    }
}


export const deleteOpcionSolaAction = async (id) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` };
    const response = await axios.delete(`/delete-opcion-sola/${id}/`, { headers, withCredentials: true });

    return response.data;
}


export const handleDragEndOpcionesSolas = async (draggableId, sourceIndex, destinationIndex) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` };
    const response = axios.post(`/update-order-opciones-solas/`, { id: draggableId, sourceIndex: sourceIndex, destinationIndex: destinationIndex }, { headers, withCredentials: true })

    if (response.data.authenticated) {
        return response.data
    }
}


// Imágenes


export const imagesActionDetails = async () => {
    const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('access_token')}` }
    const response = await axios.get(`/get-images/`, { headers, withCredentials: true });

    if (response.data.authenticated) {
        return response.data.images
    }
}


export const uploadFileHandler = async (id, imagen) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        }

        const { data } = await axios.post(`/image-producto/`, { 'producto_id': id, 'imagen': imagen }, config)

    } catch (error) {
        console.log('error')
    }
}


export const uploadExistingFileHandler = async (id, selectedImagen, model) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        }
        const { data } = await axios.post(`/existing-image-producto/`, { 'id': parseInt(id), 'imagen': selectedImagen, 'modelo': model }, config)

    } catch (error) {
        console.log('error')
    }
}


export const uploadSingleImage = async (imagen, name) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        }

        const response = await axios.post(`/post-image/`, { 'imagen': imagen, 'nombre': name }, config)

        if (response.data.authenticated) {
            return response.data.url
        }
    } catch (error) {
        console.log('error')
    }
}


export const deleteSingleImage = async (imagen) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        }

        const response = await axios.post(`/delete-image/`, { 'imagen': imagen }, config)

        if (response.data.authenticated) {
            return response.data.deleted
        }
    } catch (error) {
        console.log('error')
    }
}


export const updateSingleImage = async (imagen, name) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        }

        const response = await axios.post(`/update-image/`, { 'imagen': imagen, 'nombre': name }, config)

        if (response.data.authenticated) {
            return response.data.url
        }
    } catch (error) {
        console.log('error')
    }
}


const extension = (image) => {
    const extensionesImagenes = [".jpg", ".jpeg", ".png", ".gif", ".tiff", ".tif", ".bmp", ".svg", ".raw", ".webp", ".ico", ".psd", ".ai", ".eps", ".pdf"];


    for (let i = 0; i < extensionesImagenes.length; i++) {
      if (image.includes(extensionesImagenes[i])) {
        const extension = extensionesImagenes[i];
        const palabraSinExtension = image.replace(extension, "").replace('/media/', '');
        return palabraSinExtension;
      }
    }

    return image.replace('/media/', '');
}


export const searchImages = async (titulo) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('access_token')}`
            },
            params: {
                title: titulo // Pasar el título como parámetro de consulta
            }
        }

        const response = await axios.get(`/search-images/`, config)

        if (response.data.authenticated) {
            const images = response.data.images.map((imageUrl) => ({
                imageUrl,
                name: extension(imageUrl)
            }));

            return images;
        }
    } catch (error) {
        console.log('error')
    }
}