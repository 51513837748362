// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Raleway:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\r\n  box-sizing: border-box;\r\n  padding: 0;\r\n  margin: 0;\r\n}\r\n\r\nbody {\r\n  font-family: 'Poppins', sans-serif;\r\n  background-color: white;\r\n}\r\n\r\n::selection {\r\n  background-color: black;\r\n  color: white;\r\n}\r\n\r\n::-webkit-scrollbar {\r\n  margin: 0;\r\n  padding: 0;\r\n  width: 10px;\r\n}\r\n\r\n::-webkit-scrollbar-track {\r\n  background-color: transparent;\r\n}\r\n\r\n::-webkit-scrollbar-thumb {\r\n  background-color: rgba(0, 0, 0, 0.242);\r\n}\r\n\r\n.container {\r\n  width: 85%;\r\n  max-width: 1200px;\r\n  margin: auto;\r\n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAGA;EACE,sBAAsB;EACtB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,kCAAkC;EAClC,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,SAAS;EACT,UAAU;EACV,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,YAAY;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Raleway:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');\r\n@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\r\n\r\n* {\r\n  box-sizing: border-box;\r\n  padding: 0;\r\n  margin: 0;\r\n}\r\n\r\nbody {\r\n  font-family: 'Poppins', sans-serif;\r\n  background-color: white;\r\n}\r\n\r\n::selection {\r\n  background-color: black;\r\n  color: white;\r\n}\r\n\r\n::-webkit-scrollbar {\r\n  margin: 0;\r\n  padding: 0;\r\n  width: 10px;\r\n}\r\n\r\n::-webkit-scrollbar-track {\r\n  background-color: transparent;\r\n}\r\n\r\n::-webkit-scrollbar-thumb {\r\n  background-color: rgba(0, 0, 0, 0.242);\r\n}\r\n\r\n.container {\r\n  width: 85%;\r\n  max-width: 1200px;\r\n  margin: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
