import { useEffect, useState, } from "react";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import './ItemList.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Loader from "../Loader/Loader";


const ItemList = ({ itemsList, deleteItems, listItems, namePCat, nameSCat, navigateAdd, handleDrag, editItem, thenGoTo, isLoading }) => {
    const [items, setItems] = useState(itemsList);

    const navigate = useNavigate();

    const sweet = (name, id) => {
        Swal.fire({
            icon: "warning",
            title: `¿Quiere eliminar "${name}"?`,
            text: `Todo lo que contenga "${name}" se perderá.`,
            showConfirmButton: true,
            confirmButtonText: 'Continuar',
            showDenyButton: true,
            denyButtonText: `Cancelar`
          }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
            }
          })
    }

    const handleDelete = (id) => {
        deleteItems(id)
            .then(() => setItems(items.filter((item) => item.id !== id)));
    }    

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleDragEnd = (result) => {
        const { source, destination, draggableId } = result;
      
        if (!destination) {
          return;
        }
      
        if (source.index === destination.index && source.droppableId === destination.droppableId) {
          return;
        }
      
        handleDrag(draggableId, source.index, destination.index)
          .then(() => {
            listItems().then(data => setItems(data));
        })
      
        const reorderedCategories = reorder(items, source.index, destination.index);
        setItems(reorderedCategories);
      }
    

    useEffect(() => {
        setItems(itemsList);
    }, [itemsList]);


    return (
        <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
            <div className="container">
                <div className="navegation-items">
                    <Link to={`/control-panel`}>
                        <span>Menús</span>
                    </Link>
                    <span>{namePCat !== 'menús' ? '/' : ''}</span>
                    <Link className="with-ellipsis">{namePCat !== 'menús' ? namePCat.charAt(0).toUpperCase() + namePCat.slice(1) : ''}</Link>
                </div>
                <div className="title-section">
                    <h3>{namePCat.charAt(0).toUpperCase() + namePCat.slice(1)}</h3>
                    <button onClick={() => navigate(navigateAdd)} className="btn-normal"><i className="fa-solid fa-plus"></i>Agregar {nameSCat}</button>
                    <button onClick={() => navigate(navigateAdd)} className="btn-mobile"><i className="fa-solid fa-plus"></i></button>
                </div>
                { isLoading ? <Loader />
                : 
                <Droppable droppableId="items">
                    {(droppableProvided) => (                            
                        <div
                            {...droppableProvided.droppableProps}
                            ref={droppableProvided.innerRef}
                            className="categories-cards"
                        >
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(draggableProvided) => (                
                                        <div {...draggableProvided.draggableProps} ref={draggableProvided.innerRef} {...draggableProvided.dragHandleProps} className="category-card">
                                            <div className="category-card-contenedor">
                                                <Link to={`/control-panel/${thenGoTo}${item.id}`}>
                                                    <div className="first-div">
                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                        <img src={`${item.imagen}`} alt="" />
                                                    </div>
                                                </Link>
                                                <div className="buttons-card">
                                                    <Link to={`/control-panel/${thenGoTo}${item.id}`}>
                                                        <div className="buttons-card__title">
                                                            <h3 className={`one-line ${ item.visibilidad ? 'one-line-invisible' : ''}`}>
                                                                <i className="fa-regular fa-eye-slash"></i>
                                                                {item.nombre}
                                                            </h3>
                                                            <p>{ item.secciones ? item.secciones.length : item.productos.length } {nameSCat === 'menú' ? 'secciones' : 'productos'}</p>
                                                        </div>
                                                    </Link>
                                                    <div className="buttons-card-buttons">
                                                        <button onClick={() => sweet(item.nombre, item.id)} className="button-delete">Eliminar</button>
                                                        <button onClick={() => navigate(`${editItem}/${item.id}`)} className="button-edit">Editar</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to={`/control-panel/${thenGoTo}${item.id}`}>
                                                <div className="chevron-category">
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </div>    
                                            </Link>                                            
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
                }
                {items.length === 0 ? <div className="container items-none"><h2>{namePCat === 'menús' ? '' : `No hay secciones aún...`}</h2></div> : '' }
            </div>
        </DragDropContext>
    );
}

export default ItemList;