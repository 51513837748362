import { deleteSingleImage, imagesActionDetails, updateSingleImage, searchImages } from '../../actions/categoryActions';
import DragImage from '../DragImage/DragImage';
import { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { LazyLoadImage } from "react-lazy-load-image-component";


const ImageGallery = ({ onClose, onImageSelect, onChangeN }) => {
  const [images, setImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [image, setImage] = useState();
  const [nameImage, setNameImage] = useState('');
  const [exist, setExist] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);


  const handleImageHover = (index) => {
    setHoveredIndex(index);
  };

  const handleSearchChange = async (event) => {
    const searchValue = event.target.value;
    if (searchValue !== '') {
      try {
        const data = await searchImages(searchValue);
        setImages(data);
      } catch (error) {
        console.error(error);
      }
    } else {
      fetchImages(); // Volver a cargar todas las imágenes cuando la búsqueda está vacía
    }
  };

  const handleImageClick = (imageUrl, index) => {
    setImage(imageUrl);
    setExist(true);
    setSelectedImageIndex(index); // Actualiza el índice de la imagen seleccionada
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
    setExist(false);
  };

  const handleNameImageChange = (event) => {
    setNameImage(event.target.value);
    setExist(false);
  };

  const handleNameChange = (index, value) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages[index].name = value;
      return updatedImages;
    });
  };

  const handleNameBlur = (index) => {
    const image = images[index];
    const { imageUrl, name } = image;
    if (extension(imageUrl) !== name) {
      changeName(imageUrl, name);
    }
  };

  const handleSend = () => {
    if (image) {
      onImageSelect(image, exist, nameImage);
      onClose();
    }
  }

  const extension = (image) => {
    const extensionesImagenes = [".jpg", ".jpeg", ".png", ".gif", ".tiff", ".tif", ".bmp", ".svg", ".raw", ".webp", ".ico", ".psd", ".ai", ".eps", ".pdf"];


    for (let i = 0; i < extensionesImagenes.length; i++) {
      if (image.includes(extensionesImagenes[i])) {
        const extension = extensionesImagenes[i];
        const palabraSinExtension = image.replace(extension, "").replace('/media/', '');
        return palabraSinExtension;
      }
    }

    return image.replace('/media/', '');
  }

  const changeName = (image2, name) => {
    updateSingleImage(image2, name).then((data) => {
      setNameImage(data);
      onChangeN(data);
    });
  }

  const handleDeleteImage = (imageUrl) => {
    deleteSingleImage(imageUrl).then(() => setRefresh(!refresh));
  }

  const fetchImages = async () => {
    try {
      const data = await imagesActionDetails();
      const updatedImages = data.map((imageUrl) => ({
        imageUrl,
        name: extension(imageUrl)
      }));
      setImages(updatedImages);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    fetchImages();

  }, [refresh]);

  return (
    <TransitionGroup className="image-gallery">
      <CSSTransition classNames="modal" timeout={300}>
        <div className="modal">
          <div className="modal-content">
            <div className='modal-title'>
              <h2>Galería de imágenes</h2>
              <button type='button' onClick={onClose} className='close-button-modal'><i className="fa-solid fa-xmark"></i></button>
            </div>
            <div className='modal-children'>
              <div className="dropImage">
                <DragImage onChange={(e) => handleImageChange(e)} onChangeName={(e) => handleNameImageChange(e)} />
              </div>
              <div className='images-files'>
                <input type="text" className='input-search' placeholder='Buscar...' onChange={(e) => handleSearchChange(e)} name='buscar' />
                <div className={`image-gallery-container${images.length === 0 ? '-flex' : ''}`}>
                  { images.length !== 0 ? 
                  (images.map((image, index) => (
                    image.imageUrl.includes('/media/private') ? ""
                    : 
                    <div className="image-gallery-container__child" key={image.imageUrl} onMouseEnter={() => handleImageHover(index)} onMouseLeave={() => handleImageHover(null)}>
                    <div className="hidd">
                      <LazyLoadImage
                        key={index}
                        src={`${image.imageUrl}`}
                        className={`image-gallery-container__img ${selectedImageIndex === index ? 'selected' : ''}`}
                        onClick={() => handleImageClick(image.imageUrl, index)}
                        alt={image.imageUrl}
                        />
                    </div>
                    <input
                      type="text"
                      className="input-name-file"
                      value={image.name}
                      onChange={(e) => handleNameChange(index, e.currentTarget.value)}
                      onBlur={() => handleNameBlur(index)}
                      name={image.imageUrl}
                    />
                    <button type="button" className={`btn-delete ${hoveredIndex === index ? 'visible' : ''}`} onClick={() => handleDeleteImage(image.imageUrl)}><i className="fa-solid fa-trash"></i></button>
                  </div>
                  ))) : <h3 style={{ textAlign: 'center', fontSize: 18, marginTop: 90 }}>Sin resultados</h3>
                }

                </div>
              </div>
            </div>
            <div className="modal-final">
              <button type='button' onClick={handleSend}>Agregar imagen</button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>

  );
};

export default ImageGallery;