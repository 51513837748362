import "./Menu.css";
import Select from "../Select/Select";
import Footer from "../Footer/Footer";
import Loader from "../Loader/Loader";
import ButtonUp from "../ButtonUp/ButtonUp";
import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";
import { listCategoriesAll } from "../../actions/categoryActions";
import { useState, useEffect } from "react";
import { orderBy } from "lodash";

const Menu = () => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const listProducts = (category) => {
    setCategory(category);
  };

  useEffect(() => {
    setIsLoading(true);

    listCategoriesAll().then((data) => {
      const orderedCategories = data.map((category) => {
        const orderedSections = orderBy(category.secciones, ["orden"], ["asc"]);
        const orderedProducts = orderedSections.map((section) => {
          const orderedSectionProducts = orderBy(
            section.productos,
            ["orden"],
            ["asc"]
          );
          return { ...section, productos: orderedSectionProducts };
        });
        return { ...category, secciones: orderedProducts };
      });
      console.log(orderedCategories);
      setCategories(orderedCategories);
      setIsLoading(false);
    });
  }, []);

  return (
    <ParallaxProvider>
      <div className="menues">
        <div className="contenedor-parallax">
          {window.innerWidth < 700 ? (
            <img
              src={`/media/private/local2.jpg`}
              className="contenedor-parallax__img contenedor-parallax__img-right"
              alt=""
            />
          ) : (
            <ParallaxBanner style={{ height: "100%" }}>
              <ParallaxBannerLayer
                image={`/media/private/local2.jpg`}
                speed={-50}
                style={{
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </ParallaxBanner>
          )}
        </div>
        <div className="container">
          <Select categories={categories} handleClick={listProducts} />
          {isLoading ? (
            <Loader />
          ) : category && category.visibilidad ? (
            <div>
              {category.secciones.map((section) => {
                return (
                  <>
                    { section.visibilidad ? 
                      <div className="section">
                    <h3 className='section__nombre'>{section.nombre === 'Nuestra carta' ? 'Nuestra carta' : section.nombre}</h3>
                    <p className='section__descripcion'>{section.descripcion}</p>
                    {section.nombre === 'Nuestra carta' ? (<div className="menu-ejecutivo"><img src={`${section.imagen}`} alt="" /></div>) : (
                      section.productos !== 0 ? (
                        <div className="menu-products">
                          {section.productos.map((product) => (
                            product.visibilidad ? (
                              <div className="container-product" key={product.id}>
                                <div className="menu-products__product">
                                  <img src={`${product.imagen}`} alt="" />
                                  <h4>{product.nombre}</h4>
                                  <div className="linea-separadora"></div>
                                  {product.descripcion ? <p>{product.descripcion}</p> : ''}
                                  <span>$ {product.precio.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumIntegerDigits: 1, useGrouping: true })}</span>
                                  {product.opciones.length !== 0 && (
                                    <div className="product-options">
                                      {product.opciones.map((opcion) => (
                                        opcion.opciones.length !== 0 && opcion.opciones.map((op) => (
                                          op.precio === 0 ? (
                                            <p key={op.id}>{op.nombre}</p>
                                          ) : (
                                            <div className="opcion-con-precio" key={op.id}>
                                              <p>{op.nombre}</p>
                                              <p className='opcion-con-precio__dots'></p>
                                              <p className='opcion-con-precio__p'>$ {op.precio.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumIntegerDigits: 1, useGrouping: true })}</p>
                                            </div>
                                          )
                                        ))
                                      ))}
                                    </div>
                                  )}
                                  <div></div>
                                </div>
                              </div>
                            ) : ''
                          ))}
                        </div>
                      ) : '')}
                      </div>
                  : '' }
                  </>
                )
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
      <ButtonUp />
    </ParallaxProvider>
  );
};

export default Menu;
