import axios from 'axios';
import { Outlet, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from '../Loader/Loader';


const PrivateRoute = () => {
    const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
      const checkAuth = async () => {
        try {
            const response = await axios.get(`/users/check-auth/`, { withCredentials: true });
  
            if (response.data.authenticated) {
              setUserIsAuthenticated(true);
            } else {
              setUserIsAuthenticated(false);
            }
        } catch (error) {
            console.log(error)
            // si la solicitud falla, el usuario no está autenticado
            setUserIsAuthenticated(false);
        }
        setIsLoading(false);

      };
  
      checkAuth();
    }, [userIsAuthenticated])

    if (isLoading) {
        return <div className="container"><Loader /></div>;
    }

    return (
        userIsAuthenticated ? <Outlet/> : <Navigate to='/'/>
    )
}

export default PrivateRoute;
