import { useEffect, useState, } from "react";
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import './ItemListProducts.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { listProductos, seccionActionDetails, deleteProductoAction, handleDragEndProductos } from "../../actions/categoryActions";
import Loader from "../Loader/Loader";


const ItemListProducts = ({ seccionId, nombreMenu }) => {
    const [nombreP, setNombreP] = useState('');
    const [nombreS, setNombreS] = useState('');
    const [items, setItems] = useState([]);
    const [ordenAble, setOrdenAble] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const { menuid, seccionid } = useParams();
    
    const sweet = (name, id) => {
        Swal.fire({
            icon: "warning",
            title: `¿Quiere eliminar "${name}"?`,
            showConfirmButton: true,
            confirmButtonText: 'Continuar',
            showDenyButton: true,
            denyButtonText: `Cancelar`
          })
        .then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
            }
        })
    }

    const handleDelete = (id) => {
        deleteProductoAction(id)
            .then(() => setItems(items.filter((item) => item.id !== id)));
    }    

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleDragEnd = (result) => {
        const { source, destination, draggableId } = result;
      
        if (!destination) {
          return;
        }
      
        if (source.index === destination.index && source.droppableId === destination.droppableId) {
          return;
        }
      
        handleDragEndProductos(draggableId, source.index, destination.index)
          .then(() => {
            setIsLoading(true);
            listProductos(seccionid).then(data => setItems(data)).then(() => setIsLoading(false));
        })
      
        const reorderedProductos = reorder(items, source.index, destination.index);
        setItems(reorderedProductos);
    }
    
    useEffect(() => {
        listProductos(seccionid).then((data) => {
            setItems(data);
            setIsLoading(false);
        })
        // setNombreP('productos');
        setNombreS('plato');
        seccionActionDetails(seccionid).then(data => setNombreP(data.seccion.nombre));
    }, [seccionId]);


    return (
        <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
            <div className="container">
                <div className="navegation-items">
                    <Link to={`/control-panel`}>
                        <span>Menús</span>
                    </Link>
                    <span>/</span>
                    <Link to={`/control-panel/${menuid}`} className="with-ellipsis">
                        <span>{ nombreMenu.charAt(0).toUpperCase() + nombreMenu.slice(1) }</span>
                    </Link>
                    <span>/</span>
                    <Link className="with-ellipsis">{ nombreP.charAt(0).toUpperCase() + nombreP.slice(1) }</Link>
                </div>
                <div className="title-section">
                    <h3>{ nombreP.charAt(0).toUpperCase() + nombreP.slice(1) }</h3>
                    <div className="title-section-btns">
                        { window.innerWidth < 600 
                        ? "" 
                        : <button type="button" className="btn-ordenar" onClick={() => setOrdenAble(!ordenAble)}><i className="fa-solid fa-bars-staggered"></i>{ordenAble ? 'Terminar Orden' : 'Ordenar'}</button>
                        }
                        <button onClick={() => navigate(`/control-panel/addProduct/${menuid}/${seccionid}`)} className="btn-normal"><i className="fa-solid fa-plus"></i>Agregar {nombreS}</button>
                        <button onClick={() => navigate(`/control-panel/addProduct/${menuid}/${seccionid}`)} className="btn-mobile"><i className="fa-solid fa-plus"></i></button>
                    </div>
                </div>
                { isLoading ? <Loader />
                : 
                <Droppable droppableId="items" direction="vertical">
                    {(droppableProvided) => (                            
                        <div
                            {...droppableProvided.droppableProps}
                            ref={droppableProvided.innerRef}
                            className={`products-cards${ ordenAble || window.innerWidth < 600 ? '-flex' : '' }`}
                        >
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index} isDragDisabled={!ordenAble}>
                                    {(draggableProvided) => (                
                                        <div {...draggableProvided.draggableProps} ref={draggableProvided.innerRef} {...draggableProvided.dragHandleProps} className={`product-card${ ordenAble || window.innerWidth < 600 ? '-flex' : '' }`}>
                                            <div className={`products-card-contenedor${ ordenAble || window.innerWidth < 600 ? '-flex' : '' }`}>
                                                    <div className="first-div">
                                                        <div className="first-div__interior">
                                                            <div className="first-div__interior--another">
                                                                <button onClick={() => sweet(item.nombre, item.id)} className="btn-interior-delete"><i className="fa-solid fa-trash"></i></button>
                                                            </div>
                                                            <button onClick={() => navigate(`/control-panel/editProduct/${menuid}/${seccionid}/${item.id}`)} className="btn-interior-edit">Editar</button>
                                                        </div>
                                                        <img src={`${item.imagen}`} alt="" className={`product-card-img${ !item.visibilidad ? '-invisible' : ''}`}/>
                                                    </div>
                                                <div className="buttons-card">
                                                        <div className="buttons-card__title">
                                                            <h3 className="one-line">{ !item.visibilidad && ordenAble ? <i className="fa-regular fa-eye-slash"></i> : '' }{item.nombre}</h3>
                                                            <p>$ {item.precio.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumIntegerDigits: 1, useGrouping: true})}</p>
                                                        </div>
                                                        { ordenAble || window.innerWidth < 600 ? 
                                                        (<div className="first-div__interior-flex">
                                                            <div className="first-div__interior--another-flex">
                                                                <button onClick={() => sweet(item.nombre, item.id)} className="button-delete">Eliminar</button>
                                                            </div>
                                                            <button onClick={() => navigate(`/control-panel/editProduct/${menuid}/${seccionid}/${item.id}`)} className="button-edit">Editar</button>
                                                        </div>) : ""
                                                        }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
                }
                { !isLoading && items.length === 0 ? <div className="container items-none"><h2>{nombreP === 'menús' ? '' : `No hay productos aún...`}</h2></div> : '' }
            </div>
        </DragDropContext>
    );
}

export default ItemListProducts;