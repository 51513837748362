import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {createSeccionAction, listSecciones, uploadExistingFileHandler, uploadSingleImage, getMenu} from '../../actions/categoryActions';
import './AddSeccion.css';
import ImageGallery from '../ImageGallery/ImageGallery';
import Swal from 'sweetalert2'


const AddSeccion = () => {
  const [nombre_categoria, setNombreCategoria] = useState('');
  const [cambioImagen, setCambioImagen] = useState(false)
  const [descripcion, setDescripcion] = useState('');
  const navigate = useNavigate();
  const { menuid } = useParams()
  const [finish, setFinish] = useState('Agregar');
  const [openGallery, setOpenGallery] = useState(false);
  const [visible, setVisible] = useState(true);
  const [selectedImagen, setSelectedImagen] = useState('');


  const sweet = () => {
    Swal.fire({
        icon: "warning",
        title: `Esa sección ya existe y no se puede repetir`,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      })
  }

  const handleSubmit = (e) => {
      e.preventDefault();
      setFinish('Cargando');

      if (nombre_categoria === 'Nuestra carta') {
        getMenu().then((data) => data ? sweet() : (
          createSeccionAction(nombre_categoria, descripcion, menuid, visible)
          .then(data => cambioImagen ? uploadExistingFileHandler(data.seccion.id, selectedImagen, 'seccion') : '')
          .then(() => listSecciones(menuid))
          .then(() => navigate(`/control-panel/${menuid}`))
        )).then(() => setFinish('Agregar'));
      } else {
        createSeccionAction(nombre_categoria, descripcion, menuid, visible)
        .then(data => cambioImagen ? uploadExistingFileHandler(data.seccion.id, selectedImagen, 'seccion') : '')
        .then(() => listSecciones(menuid))
        .then(() => navigate(`/control-panel/${menuid}`));
      }
  }

  const handleImageChange = (event, exist, nameImage) => {
    if (exist) {
      let i = event.replace('/media/', '');
      setSelectedImagen(i);
      setCambioImagen(true);
    } else {
      uploadSingleImage(event, nameImage).then((data) => {
        setSelectedImagen(data.replace('/media/', '')) 
        });
      setCambioImagen(true);
    }
  };

  const handleCancel = () => {
    navigate(`/control-panel/${menuid}`);
  }

  const cambioDeNombre = (nombre) => {
    setSelectedImagen(nombre.replace('/media/', ''))
  }


  return (
    <div className="container">
      <div className="form-container">
        { openGallery && <ImageGallery onClose={() => setOpenGallery(false)} onImageSelect={handleImageChange} onChangeN={cambioDeNombre} /> }
        <form className="form__group" onSubmit={handleSubmit}>
          <h1 className="form__group--title">Agregar sección</h1>

          <div className="inputContainer">
            <label htmlFor="" className="form__label">Imagen</label>
            { selectedImagen ? <div className='preview-image-container' onClick={() => setOpenGallery(true)}><span>Agregar imagen</span><img className='preview-image' src={`/media/${selectedImagen}`} alt="" /></div> : <div className='preview-image-noexist' onClick={() => setOpenGallery(true)}> <span>Agregar imagen</span> </div> }
          </div>

          <div className="inputContainer">
            <label htmlFor="name" className="form__label">Nombre de la sección</label>
            <input type="text" name='name' className="form__input" placeholder='Mi nueva sección' value={nombre_categoria} onChange={(e) => setNombreCategoria(e.target.value)} maxLength={200} id="nombre_categoria" required/>
          </div>

          <div className="inputContainer">
            <label htmlFor="" className="form__label">Descripción de la sección</label>
            <textarea  value={descripcion} onChange={(e) => setDescripcion(e.target.value)} id="descripcion" maxLength={300}>Contenido del textarea</textarea>
          </div>

          <div className="checkbox-wrapper-4">
            <input className="inp-cbx" id={`option-visibility`} type="checkbox" checked={visible} onChange={() => setVisible(!visible)} />
            <label className="cbx" htmlFor={`option-visibility`}><span>
              <svg width="12px" height="10px">
                <use xlinkHref="#check-4"></use>
              </svg></span><span>Visible</span></label>
            <svg className="inline-svg">
              <symbol id="check-4" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </symbol>
            </svg>
          </div>
          
          <div className='form_container__buttons'>
            <button onClick={handleCancel} className='button-cancel'>Cancelar</button>
            <button type='submit'>{ finish }</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddSeccion;