import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { updateProductoAction, productoActionDetails, OpcionesActionDetails, uploadExistingFileHandler, uploadSingleImage, deleteOpcionAction } from '../../actions/categoryActions';
import { useParams } from 'react-router-dom';
import OptionsList from '../OptionsList/OptionsList';
import ExistingOptions from '../ExistingOptions/ExistingOptions';
import ImageGallery from '../ImageGallery/ImageGallery';


const EditProducto = () => {

  const { menuid, seccionid, id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [cambioImagen, setCambioImagen] = useState(false)
  const [nombre_categoria, setNombreCategoria] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [precio, setPrecio] = useState();
  const [finish, setFinish] = useState('Editar');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [openExisting, setOpenExisting] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [selectedImagen, setSelectedImagen] = useState('');
  const [visible, setVisible] = useState();


  const handleSubmitOptions = (selected) => {
    let option = [selected];
    setOptions(prevOpciones => {
      const newOptions = [...prevOpciones, ...option];
      return newOptions;
    });
  }

  const handleCancel = () => {
    navigate(`/control-panel/${menuid}/${seccionid}`);
  }
  
  const handleOptionDelete = (id) => {
    setOptions(prevOpciones => prevOpciones.filter(op => op.id !== parseInt(id)));
  }

  const submitHandler = (e) => {
    e.preventDefault();
    setFinish('Cargando...')
    updateProductoAction({ id: id, nombre: nombre_categoria, descripcion: descripcion, precio: precio, opciones: options, visibilidad: visible })
      .then(() => cambioImagen ? uploadExistingFileHandler(id, selectedImagen, 'producto')  : '')
      .then(() => navigate(`/control-panel/${menuid}/${seccionid}`));
  }

  const handleImageChange = (event, exist, nameImage) => {
    if (exist) {
      let i = event.replace('/media/', '');
      setSelectedImagen(i);
      setCambioImagen(true);
    } else {
      uploadSingleImage(event, nameImage).then((data) => setSelectedImagen(data.replace('/media/', '')));
      setCambioImagen(true);
    }
  };

  const handleExistingOptionsSubmit = (selected) => {
    setOptions(prevOpciones => {
      const newOptions = [...prevOpciones, ...selected];
      const uniqueOptions = newOptions.filter((option, index, self) =>
        index === self.findIndex((t) => (
          t.id === option.id
        ))
      );
      return uniqueOptions;
    });
  };
  
  const cambioDeNombre = (nombre) => {
    setSelectedImagen(nombre.replace('/media/', ''))
  }  

  const handleWheel = (event) => {
    event.preventDefault();
  }

  const deleteOpcion = (id) => {
    deleteOpcionAction(id);
  }

  useEffect(() => {
    productoActionDetails(id).then(data => {
      setInfo(data.producto)
      setOptions(data.producto.opciones)
      setVisible(data.producto.visibilidad)
    })
  }, [])

  return (
    <div className="container">
      <div className="form-container">
        { openGallery && <ImageGallery onClose={() => setOpenGallery(false)} onImageSelect={handleImageChange} onChangeN={cambioDeNombre} /> }
        { openExisting && <ExistingOptions isOpen={openExisting} onClose={() => setOpenExisting(false)} onSubmit={handleExistingOptionsSubmit} optionsFun={OpcionesActionDetails} onDelete={deleteOpcion} /> }
        <OptionsList isOpen={open} onClose={() => setOpen(false)} onSubmit={handleSubmitOptions} />
        <form className="form__group" onSubmit={submitHandler}>
          <h1 className="form__group--title">Editar producto</h1>

          <div className="inputContainer">
            <label htmlFor="" className="form__label">Imagen</label>
            { selectedImagen || info.imagen !== '/pukka.png' ? <div className='preview-image-container' onClick={() => setOpenGallery(true)}><span>Agregar imagen</span><img className='preview-image' src={`${selectedImagen ? (selectedImagen.includes('/media/') ? "" : `/media/${selectedImagen}`) : info.imagen}`} alt="" /></div> : <div className='preview-image-noexist' onClick={() => setOpenGallery(true)}> <span>Agregar imagen</span> </div> }
          </div>

          <div className='inputContainer-row'>
            <div className="inputContainer" style={{ width: "100%" }}>
              <label htmlFor="name" className="form__label">Nombre</label>
              <input type="text" defaultValue={info.nombre} name='name' className="form__input" placeholder='Mi nuevo producto' onChange={(e) => setNombreCategoria(e.currentTarget.value)} id="nombre_categoria" maxLength={200} required />
            </div>

            <div className="inputContainer" style={window.innerWidth < 600 ? { width: "100%" } : { width: "25%" }}>
              <label htmlFor="name" className="form__label">Precio</label>
              <div className="inputWithIcon">
                <i className="fa-solid fa-dollar-sign inputIcon"></i>
                <input type="number" name="price" onWheel={handleWheel} className="form__input" placeholder="0" defaultValue={info.precio} onChange={(e) => setPrecio(e.currentTarget.value)} id="precio-field" />
              </div>
            </div>
          </div>

          <div className="inputContainer">
            <label htmlFor="" className="form__label">Descripción</label>
            <textarea defaultValue={info.descripcion} onChange={(e) => setDescripcion(e.currentTarget.value)} id="descripcion" maxLength={300}></textarea>
          </div>

          <div className="inputContainer">
            <label htmlFor="" className="form__label">Opciones del plato</label>
            {options.length !== 0 ? (options.map((opcion, index) =>
              <div className='opcion--selected' key={index}>
                <div>
                  <p className='opcion--selected-name'>{opcion.nombre}</p>
                  <p className='opcion--selected-description'>
                  { opcion.opciones.length !== 0 && opcion.opciones
                      .map((subopcion) => subopcion.nombre)
                      .slice(0, -1)
                      .join(', ')}{' '}
                    { opcion.opciones.length !== 0 && opcion.opciones.length > 1 && 'y '}
                    { opcion.opciones.length !== 0 && opcion.opciones[opcion.opciones.length - 1].nombre}
                  </p>
                </div>
                <button type='button' onClick={() => handleOptionDelete(opcion.id)} className='button-special'><i className="fa-solid fa-trash"></i></button>
              </div>
            )) : ""}
            <div className='add-element'>
              <div className='agregar-opcion' onMouseOver={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)}>
                {showButtons === false ? (
                  <div className='agregar-opcion__plus'>
                    <i className="fa-solid fa-plus"></i>
                  </div>
                ) : (
                  <div className='agregar-opcion__botones'>
                    <button type='button' onClick={() => setOpen(true)}>Nueva opción</button>
                    <button type='button' onClick={() => setOpenExisting(true)}>{ window.innerWidth < 500 ? 'Selecciona...' : 'Selecciona de las existentes' }</button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="checkbox-wrapper-4">
            <input className="inp-cbx" id={`option-visibility`} type="checkbox" checked={visible} onChange={() => setVisible(!visible)} />
            <label className="cbx" htmlFor={`option-visibility`}><span>
              <svg width="12px" height="10px">
                <use xlinkHref="#check-4"></use>
              </svg></span><span>Visible</span></label>
            <svg className="inline-svg">
              <symbol id="check-4" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </symbol>
            </svg>
          </div>

          <div className='form_container__buttons'>
            <button onClick={handleCancel} className='button-cancel'>Cancelar</button>
            <button type='submit'>{finish}</button>
          </div>
        </form>
      </div>
    </div>
  )
}


export default EditProducto;