import './Loader.css';


const Loader = () => {

    return (
        <div className="container">
            <div className="container-loader">
                <div className="loader"></div>
            </div>
        </div>
    )

}


export default Loader;