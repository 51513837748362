import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
    userLoginReducer,
    userRegisterReducer,
    userSoloReducer,
    userListReducer,
    userEditReducer,

} from './reducers/userReducers';

import {
    categoryListReducer,
    categoryCreateReducer,
    categoryDetailsReducer,
    createProductReducer,
    categoryDeleteReducer,
    categoryUpdateReducer,

} from './reducers/categoryReducers';

const reducer = combineReducers({
    // User stuff
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userSolo: userSoloReducer,
    userList: userListReducer,
    userEdit: userEditReducer,

    // Category stuff
    categoryList: categoryListReducer,
    categoryCreate: categoryCreateReducer,
    soloCategory: categoryDetailsReducer,
    productCategory: createProductReducer,
    deleteCategory: categoryDeleteReducer,
    updateCategory: categoryUpdateReducer,

})

const userInfoStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
    userLogin: { userInfo: userInfoStorage }
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;