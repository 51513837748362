// LOGIN
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_SUCCESS';

// LOGOUT
export const USER_LOGOUT = 'USER_LOGOUT';

// REGISTER
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

// EDIT
export const USER_EDIT_REQUEST = 'USER_EDIT_REQUEST';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_FAIL = 'USER_EDIT_FAIL';
export const USER_EDIT_RESET = 'USER_EDIT_RESET';

// ONE USER
export const USER_SOLO_REQUEST = 'USER_SOLO_REQUEST';
export const USER_SOLO_SUCCESS = 'USER_SOLO_SUCCESS';
export const USER_SOLO_FAIL = 'USER_SOLO_FAIL';
export const USER_SOLO_RESET = 'USER_SOLO_RESET';

// LIST USERS
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

