import CategoryListContainer from "../CategoryListContainer/CategoryListContainer";
import Sidebar from "../SideBar/SideBar";
import './ControlPanel.css';


const ControlPanel = () => {

    return (
    <>
        <Sidebar/>
        <CategoryListContainer />
    </>
    )
}


export default ControlPanel;