import { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Loader from "../Loader/Loader";
import Swal from 'sweetalert2'


const ExistingOptions = ({ isOpen, onClose, onSubmit, optionsFun, onDelete }) => {

  const [opciones, setOpciones] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);


  const sweet = (name, id) => {
    Swal.fire({
        icon: "warning",
        title: `¿Quiere eliminar "${name}"?`,
        text: `Se eliminará de todos los productos que estén asociados al mismo.`,
        showConfirmButton: true,
        confirmButtonText: 'Continuar',
        showDenyButton: true,
        denyButtonText: `Cancelar`
      }).then((result) => {
        if (result.isConfirmed) {
            handleDelete(id);
        }
      })
}

  const handleOptionSelect = (id) => {
    setSelectedOptions({
      ...selectedOptions,
      [id]: !selectedOptions[id]
    });
  };

  const handleSave = () => {
    const selected = Object.keys(selectedOptions).filter((key) => selectedOptions[key]);
    const selectedOpciones = opciones.filter((opcion) => selected.includes(opcion.id.toString()));
    onSubmit(selectedOpciones);
    onClose();
  };

  const handleDelete = (id) => {
    onDelete(id);
  }

  useEffect(() => {
    optionsFun().then((data) => setOpciones(data)).then(() => setIsLoading(false));
  }, [handleDelete]);

  return (
    <TransitionGroup className="existing-options">
      <CSSTransition classNames="modal" timeout={300}>
        <div className="container">
        <div className="modal">
          <div className="modal-content">
            <div className='modal-title'>
              <h2>Opciones extras</h2>
              <button onClick={onClose} className='close-button-modal'><i className="fa-solid fa-xmark"></i></button>
            </div>
            <div className='modal-children'>
              {isLoading ? <Loader /> :
              <div className='mostrar-opciones'>
                {opciones.length !== 0 ? (
                  opciones.map((opcion) => (
                    <div className='opcion-sola' key={opcion.id}>
                      <div className="checkbox-wrapper-4">
                        <input className="inp-cbx" id={`option-${opcion.id}`} type="checkbox" checked={selectedOptions[opcion.id] || false} onChange={() => handleOptionSelect(opcion.id)} />
                        <label className="cbx" htmlFor={`option-${opcion.id}`}><span>
                          <svg width="12px" height="10px">
                            <use xlinkHref="#check-4"></use>
                          </svg></span><span>{opcion.nombre}</span></label>
                        <svg className="inline-svg">
                            <symbol id="check-4" viewBox="0 0 12 10">
                              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </symbol>
                          </svg>
                      </div>
                      <button type='button' onClick={() => sweet(opcion.nombre, opcion.id)} className='button-special'><i className="fa-solid fa-trash"></i></button>
                    </div>
                  ))
                ) : <h3 style={{ textAlign: 'center', fontSize: 20 }}>No hay nada aún...</h3>}
              </div>
              }
            </div>
            <div className="modal-final">
              <button onClick={handleSave}>Agregar</button>
            </div>
          </div>
        </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default ExistingOptions;