import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { updateCategoryAction, categoryActionDetails, uploadExistingFileHandler, uploadSingleImage } from '../../actions/categoryActions';
import {  useParams } from 'react-router-dom';
import ImageGallery from '../ImageGallery/ImageGallery';


const EditCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [nombre_categoria, setNombreCategoria] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [finish, setFinish] = useState('Editar');
  const [visible, setVisible] = useState();
  const [cambioImagen, setCambioImagen] = useState(false)
  const [openGallery, setOpenGallery] = useState(false);
  const [selectedImagen, setSelectedImagen] = useState('');


  const handleCancel = () => {
      navigate(`/control-panel`);
  }

  const submitHandler = (e) => {
      e.preventDefault();
      setFinish('Cargando...')
      updateCategoryAction({ id: id, nombre_categoria: nombre_categoria, descripcion_categoria: descripcion, visibilidad: visible })
          .then(() => cambioImagen ? uploadExistingFileHandler(id, selectedImagen, 'categoria')  : '')
          .then(() => navigate('/control-panel'));
  }
  
  const handleImageChange = (event, exist, nameImage) => {
    if (exist) {
      let i = event.replace('/media/', '');
      setSelectedImagen(i);
      setCambioImagen(true);
    } else {
      uploadSingleImage(event, nameImage).then((data) => setSelectedImagen(data.replace('/media/', '')));
      setCambioImagen(true);
    }
  };

  const cambioDeNombre = (nombre) => {
    setSelectedImagen(nombre)
  }  

  useEffect(() => {
      categoryActionDetails(id).then((data) => {
        setInfo(data.category);
        setVisible(data.category.visibilidad);
      })
  }, [])

return (
    <div className="container">
      <div className="form-container">
        { openGallery && <ImageGallery onClose={() => setOpenGallery(false)} onImageSelect={handleImageChange} onChangeN={cambioDeNombre} /> }
        <form className="form__group" onSubmit={submitHandler}>
          <h1 className="form__group--title">Editar menú</h1>

          <div className="inputContainer">
            <label htmlFor="imagen" className="form__label">Imagen</label>
            { selectedImagen || info.imagen !== '/pukka.png' ? <div className='preview-image-container' onClick={() => setOpenGallery(true)} id='imagen'><span>Agregar imagen</span><img className='preview-image' src={`${selectedImagen ? (selectedImagen.includes('/media/') ? "" : `/media/${selectedImagen}`) : info.imagen}`} alt="" /></div> : <div className='preview-image-noexist' onClick={() => setOpenGallery(true)} id='imagen'> <span>Agregar imagen</span> </div> }
          </div>

          <div className="inputContainer">
            <label htmlFor="nombre_categoria" className="form__label">Nombre del menú</label>
            <input defaultValue={info.nombre} type="text" name='name' className="form__input" placeholder='Mi nuevo menú' onChange={(e) => setNombreCategoria(e.currentTarget.value)} id="nombre_categoria" maxLength={100} autoComplete="on" required/>
          </div>

          <div className="inputContainer">
            <label htmlFor="" className="form__label">Descripción del menú</label>
            <textarea  defaultValue={info.descripcion} onChange={(e) => setDescripcion(e.currentTarget.value)} id="descripcion" maxLength={200}></textarea>
          </div>

          <div className="checkbox-wrapper-4">
            <input className="inp-cbx" id={`option-visibility`} type="checkbox" checked={visible} onChange={() => setVisible(!visible)} />
            <label className="cbx" htmlFor={`option-visibility`}><span>
              <svg width="12px" height="10px">
                <use xlinkHref="#check-4"></use>
              </svg></span><span>Visible</span></label>
            <svg className="inline-svg">
              <symbol id="check-4" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </symbol>
            </svg>
          </div>

          <div className='form_container__buttons'>
            <button onClick={handleCancel} className='button-cancel'>Cancelar</button>
            <button type='submit'>{ finish }</button>
          </div>
        </form>
      </div>
    </div>
    )
}       


export default EditCategory;