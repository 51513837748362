import { useState, useEffect } from 'react';
import { createOpcionesAction, createOpcionSolaAction, handleDragEndOpcionesSolas, listOpcionesSolas, updateOpcionSolaAction, deleteOpcionSolaAction } from '../../actions/categoryActions';
import './OptionsList.css';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ExistingOptions from '../ExistingOptions/ExistingOptions';


const OptionsList = ({ isOpen, onClose, onSubmit }) => {

  const [finish, setFinish] = useState('Agregar');
  const [nombre, setNombre] = useState();
  const [nombreOpcion, setNombreOpcion] = useState();
  const [precioOpcion, setPrecioOpcion] = useState(0);
  const [opciones, setOpciones] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [existingOptionsOpen, setExistingOptionsOpen] = useState(false);


  const handleExistingOptionsSubmit = (selected) => {
    setOpciones(prevOpciones => {
      const newOptions = [...prevOpciones, ...selected];
      const uniqueOptions = newOptions.filter((option, index, self) =>
        index === self.findIndex((t) => (
          t.id === option.id
        ))
      );
      return uniqueOptions;
    });
  };

  const handleOptionSubmit = (e) => {
    e.preventDefault();
    createOpcionSolaAction(nombreOpcion, precioOpcion)
      .then((data) => setOpciones([...opciones, { id: data.opcion.id, nombre: nombreOpcion, precio: precioOpcion }]))
      .then(() => setPrecioOpcion(0))
      .then(() => setShowForm(false))
  }

  const handleUpdateOpcionSola = (opcion, value) => {

    const objetoAModificar = opcion;
    objetoAModificar.precio = value;

    updateOpcionSolaAction(objetoAModificar)
      .then((data) =>
        setOpciones(prevOpciones => {
          return prevOpciones.map(opcion => {
            if (opcion.id === data.id) {
              return { ...opcion, ...data.opcion };
            } else {
              return opcion;
            }
          })
        }

        ))
  }

  const handleOptionDelete = (id) => {
    setOpciones(prevOpciones => prevOpciones.filter(op => op.id !== id));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFinish('Cargando...')
    createOpcionesAction(nombre, opciones)
      .then((data) => onSubmit(data.opcion))
      .then(() => setFinish('Agregar'))
      .then(() => onClose());
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (source.index === destination.index && source.droppableId === destination.droppableId) {
      return;
    }

    handleDragEndOpcionesSolas(draggableId, source.index, destination.index)
      .then((data) => setOpciones(data));

    const reorderedCategories = reorder(opciones, source.index, destination.index);
    setOpciones(reorderedCategories);
  }

  const deleteSingleOption = (id) => {
    deleteOpcionSolaAction(id);
  }

  useEffect(() => {
  }, [opciones]);

  return (
    <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
      <TransitionGroup>
        {isOpen && (
          <CSSTransition classNames="modal" timeout={300}>
            <div className="modal">
              <div className="modal-content">
                <div className='modal-title'>
                  <h2>Opciones de platos</h2>
                  <button onClick={onClose} style={{ padding: 0 }}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className='modal-children'>
                  <form onSubmit={handleSubmit} className='opciones-form'>
                    <div className="inputContainer">
                      <label htmlFor="name" className="form__label">Nombre</label>
                      <input type="text" name='name' className="form__input" placeholder='Mi nueva opción' id="nombre" onChange={(e) => setNombre(e.currentTarget.value)} style={window.innerWidth < 600 ? { marginRight: 0 } : { marginRight: 40 }} maxLength={100} required />
                    </div>
                    {window.innerWidth < 600
                      ? <div className='form_container__buttons'><button type='submit' style={{ margin: 0, padding: 10, paddingRight: 15, paddingLeft: 15 }}>+</button></div>
                      : <div className='form_container__buttons'><button type='submit'>{finish}</button></div>
                    }
                  </form>
                  {existingOptionsOpen && <ExistingOptions isOpen={existingOptionsOpen} onClose={() => setExistingOptionsOpen(false)} onSubmit={handleExistingOptionsSubmit} optionsFun={listOpcionesSolas} onDelete={deleteSingleOption} />}
                  <h3>Choices (Extras)</h3>
                  <Droppable droppableId="opciones">
                    {(droppableProvided) => (
                      <div className='mostrar-opciones' {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}>
                        {opciones.length !== 0 ? (opciones.map((opcion, index) =>
                          <Draggable key={opcion.id} draggableId={opcion.id.toString()} index={index}>
                            {(draggableProvided) => (
                              <div className='opcion-sola' {...draggableProvided.draggableProps} ref={draggableProvided.innerRef} {...draggableProvided.dragHandleProps}>
                                <div className="inputContainer">
                                  <input type="text" name='opcion' className="form__input" placeholder='Nueva opción' id="opcion" value={opcion.nombre} required disabled />
                                </div>
                                <div className="inputContainer">
                                  <div className="inputWithIcon">
                                    <i className="fa-solid fa-dollar-sign inputIcon"></i>
                                    <input type="number" name="opcion" className="form__input" placeholder="0" defaultValue={opcion.precio} onBlur={(e) => handleUpdateOpcionSola(opcion, e.currentTarget.value)} required id="precio-opcion" />
                                  </div>
                                </div>
                                <button onClick={() => handleOptionDelete(opcion.id)} className='button-special'><i className="fa-solid fa-trash"></i></button>
                                <div className='button-special ellipsis'><i className="fa-solid fa-ellipsis-vertical"></i></div>
                              </div>
                            )}
                          </Draggable>
                        )) : ""}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {showForm ? (
                    <form onSubmit={handleOptionSubmit} className='opciones-solas-form'>
                      <div className="inputContainer">
                        <label htmlFor="opcion" className="form__label">Nombre</label>
                        <input type="text" name='opcion' className="form__input" placeholder='Nueva opción' id="opcion" onChange={(e) => setNombreOpcion(e.currentTarget.value)} maxLength={100} required />
                      </div>
                      <div className="inputContainer">
                        <label htmlFor="opcion" className="form__label">Precio</label>
                        <div className="inputWithIcon">
                          <i className="fa-solid fa-dollar-sign inputIcon"></i>
                          <input type="number" name="opcion" className="form__input" placeholder="0" onChange={(e) => setPrecioOpcion(e.currentTarget.value)} id="precio-opcion" />
                        </div>
                      </div>
                      <button type="submit" className='button-special'><i className="fa-solid fa-check"></i></button>
                    </form>
                  ) : ("")}
                </div>
                <div className="modal-end">
                  <div className='agregar-opcion' onMouseOver={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)}>
                    {showButtons === false ? (
                      <div className='agregar-opcion__plus'>
                        <i className="fa-solid fa-plus"></i>
                      </div>
                    ) : (
                      <div className='agregar-opcion__botones'>
                        <button onClick={() => setShowForm(true)}>Nueva elección</button>
                        <button onClick={() => setExistingOptionsOpen(true)}>Selecciona de las existentes</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </DragDropContext>
  );

}


export default OptionsList;