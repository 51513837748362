import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import './Login.css';


const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [finish, setFinish] = useState('Entrar');
  const [error, setError] = useState(false);
  const navigate = useNavigate('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setFinish('Cargando...');
      const res = await axios.post(
        `/users/login/`,
        {
          username,
          password,
        },
        { withCredentials: true }
      );
      navigate('/control-panel');
    } catch (error) {
      setFinish('Entrar');
      setError(true);
    }
  };


  return (
    <div className="container">
      <div className="form-container">
        <form className="form__group" onSubmit={handleLogin}>
          <h1 className="form__group--title">Iniciar sesión</h1>

          <div className={`alert-form${error ? '' : '-hidden'}`}>
            <p>E-mail o contraseña incorrecto. Intente de nuevo.</p>
          </div>

          <div className="inputContainer">
            <label htmlFor="name" className="form__label">E-mail</label>
            <input type="text" name='name' className="form__input" value={username} onChange={(e) => setUsername(e.target.value)} required/>
          </div>

          <div className="inputContainer">
            <label htmlFor="" className="form__label">Contraseña</label>
            <input type="password" name='password' className="form__input" value={password} onChange={(e) => setPassword(e.target.value)} required/>
          </div>
          
          <div className='form_container__buttons'>
            <button type='submit'>{ finish }</button>
          </div>
        </form>
      </div>
    </div>

  );
}

export default Login;