import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation().pathname.includes('/control-panel');
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLinkClick = () => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className={`navbar-contenedor${location ? '-center' : ''}`}>
        {location ? "" : <div className='nav-oculto'></div>}
        <Link to={'/'}>
          <div className="logo"></div>
        </Link>
        {location ? "" :
          <ul className="nav-links">
            <input type="checkbox" id="checkbox_toggle" checked={menuOpen} onChange={toggleMenu} />
            <label htmlFor="checkbox_toggle" className="hamburger">&#9776;</label>
            <div className={`menu ${menuOpen ? 'open' : ''}`}>
              <li><Link to={'/'} onClick={handleLinkClick}>Inicio</Link></li>
              <li><Link to={'/menu'} onClick={handleLinkClick}>Menú</Link></li>
              <li><Link to={'/contacto'} onClick={handleLinkClick}>Contacto</Link></li>
              <li><a href="https://api.whatsapp.com/send?phone=542617227779&text=Me%20gustar%C3%ADa%20hacer%20una%20reserva..." target="_blank" rel="noopener noreferrer" >Reservas</a></li>
            </div>
          </ul>
        }
      </div>
    </nav>
  )
}

export default Navbar;
