import './DragImage.css';
import React, { useState } from 'react';


const DragImage = (props) => {

    const [file, setFile] = useState(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        props.onChange({ target: { files: [droppedFile] } });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        props.onChange(e);
    };

    return (
        <div className="drag-drop-container">
            <div
                className={`drop-zone`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}>
                {file ? (
                    <img src={URL.createObjectURL(file)} alt="Uploaded" />
                ) : (
                    'Arrastra y suelta una imagen aquí'
                )}
            </div>
            <span>ó</span>
            <input
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                className="file-input"
            />
            <div className="inputContainer" style={{ marginTop: 20 }}>
                <label htmlFor="name" className="form__label">Nombre del archivo</label>
                <input type="text" name='name' className="form__input" id="nombre" onChange={props.onChangeName} maxLength={100} required />
            </div>
        </div>
    );

}


export default DragImage;