import { useState } from 'react';
import { useNavigate } from 'react-router';
import {createCategoryAction, listCategories, uploadExistingFileHandler, uploadSingleImage} from '../../actions/categoryActions';
import './AddCategory.css';
import ImageGallery from '../ImageGallery/ImageGallery';


const AddCategory = () => {
  const [nombre_categoria, setNombreCategoria] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const navigate = useNavigate();
  const [finish, setFinish] = useState('Agregar');
  const [visible, setVisible] = useState(true);
  const [cambioImagen, setCambioImagen] = useState(false)
  const [openGallery, setOpenGallery] = useState(false);
  const [selectedImagen, setSelectedImagen] = useState('');


  const handleSubmit = (e) => {
      e.preventDefault();
      setFinish('Cargando...')
      createCategoryAction(nombre_categoria, descripcion, visible)
          .then(data => cambioImagen ? uploadExistingFileHandler(data.category.id, selectedImagen, 'categoria') : '')
          .then(() => listCategories())
          .then(() => navigate('/control-panel'));
  }

  const handleImageChange = (event, exist, nameImage) => {
    if (exist) {
      let i = event.replace('/media/', '');
      setSelectedImagen(i);
      setCambioImagen(true);
    } else {
      uploadSingleImage(event, nameImage).then((data) => {
        setSelectedImagen(data.replace('/media/', '')) 
        });
      setCambioImagen(true);
    }
  };

  const cambioDeNombre = (nombre) => {
    setSelectedImagen(nombre.replace('/media/', ''))
  }

  const handleCancel = () => {
    navigate('/control-panel');
  }

  return (
    <div className="container">
      <div className="form-container">
        { openGallery && <ImageGallery onClose={() => setOpenGallery(false)} onImageSelect={handleImageChange} onChangeN={cambioDeNombre} /> }
        <form className="form__group" onSubmit={handleSubmit}>
          <h1 className="form__group--title">Agregar menú</h1>

          <div className="inputContainer">
            <label htmlFor="" className="form__label">Imagen</label>
            { selectedImagen ? <div className='preview-image-container' onClick={() => setOpenGallery(true)}><span>Agregar imagen</span><img className='preview-image' src={`/media/${selectedImagen}`} alt="" /></div> : <div className='preview-image-noexist' onClick={() => setOpenGallery(true)}> <span>Agregar imagen</span> </div> }
          </div>

          <div className="inputContainer">
            <label htmlFor="name" className="form__label">Nombre del menú</label>
            <input type="text" name='name' className="form__input" placeholder='Mi nuevo menú' value={nombre_categoria} onChange={(e) => setNombreCategoria(e.target.value)} maxLength={100} id="nombre_categoria" required/>
          </div>

          <div className="inputContainer">
            <label htmlFor="" className="form__label">Descripción del menú</label>
            <textarea  value={descripcion} onChange={(e) => setDescripcion(e.target.value)} id="descripcion" maxLength={200}>Contenido del textarea</textarea>
          </div>

          <div className="checkbox-wrapper-4">
            <input className="inp-cbx" id={`option-visibility`} type="checkbox" checked={visible} onChange={() => setVisible(!visible)} />
            <label className="cbx" htmlFor={`option-visibility`}><span>
              <svg width="12px" height="10px">
                <use xlinkHref="#check-4"></use>
              </svg></span><span>Visible</span></label>
            <svg className="inline-svg">
              <symbol id="check-4" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </symbol>
            </svg>
          </div>
          
          <div className='form_container__buttons'>
            <button onClick={handleCancel} className='button-cancel'>Cancelar</button>
            <button type='submit'>{ finish }</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddCategory;