import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Home from './components/Home/Home';
import Menu from './components/Menu/Menu';
import Login from './components/Login/Login';
import AddCategory from './components/AddCategory/AddCategory';
import EditCategory from './components/EditCategory/EditCategory';
import Navbar from './components/Navbar/Navbar';
import ControlPanel from './components/ControlPanel/ControlPanel';
import AddSeccion from './components/AddSeccion/AddSeccion';
import EditSeccion from './components/EditSeccion/EditSeccion';
import AddProduct from './components/AddProduct/AddProduct';
import EditProducto from './components/EditProducto/EditProducto';
import NotFound from './components/NotFound/NotFound';
import Contact from './components/Contact/Contact';
import { useEffect } from 'react';


function App() {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    // Define el título dependiendo de la ruta
    let title = '';
    if (pathname === '/') {
      title = 'Pukka';
    } else if (pathname === '/menu') {
      title = 'Pukka | Menús';
    } else if (pathname === '/contacto') {
      title = 'Pukka | Contacto';
    } else if (pathname === '/login') {
      title = 'Pukka | Login';
    } else if (pathname.includes('control-panel')) {
      title = 'Pukka | Panel de control';
    } else {
      title = 'Pukka'; // Título predeterminado
    }

    // Cambia el título de la pestaña
    document.title = title;

  }, [location]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route element={<PrivateRoute />} >
          <Route path={'/control-panel'} element={<ControlPanel />} />
          <Route path={'/control-panel/:menuid'} element={<ControlPanel />}  />
          <Route path={'/control-panel/:menuid/:seccionid'} element={<ControlPanel />}  />
          <Route path={'/control-panel/addMenu'} element={<AddCategory />} />
          <Route path={'/control-panel/addSection/:menuid'} element={<AddSeccion />} />
          <Route path={'/control-panel/addProduct/:menuid/:seccionid'} element={<AddProduct />} />
          <Route path={'/control-panel/editMenu/:id'} element={<EditCategory />} />
          <Route path={'/control-panel/editSection/:menuid/:id'} element={<EditSeccion />} />
          <Route path={'/control-panel/editProduct/:menuid/:seccionid/:id'} element={<EditProducto />} />
        </Route>

        <Route path={'/'} element={<Home />} />
        <Route path={'/menu'} element={<Menu />} />
        <Route path={'/login'} element={<Login />} />
        <Route path={'/contacto'} element={<Contact />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
