import './CategoryListContainer.css';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { listCategories, deleteCategoryAction, listSecciones, deleteSeccionAction, handleDragEndCategories, handleDragEndSecciones, categoryActionDetails } from '../../actions/categoryActions';
import ItemList from '../ItemList/ItemList';
import Loader from '../Loader/Loader';
import ItemListProducts from '../ItemListProducts/ItemListProducts';


const CategoryListContainer = () => {

    const [categories, setCategories] = useState();
    const [nombreP, setNombreP] = useState('');
    const [nombreS, setNombreS] = useState('');
    const [thenGoTo, setThenGoTo] = useState('');
    const [addItem, setAddItem] = useState();
    const [loading, setLoading] = useState(true);
    const [otherLoading, setOtherLoading] = useState(false);


    const { menuid, seccionid } = useParams();

    useEffect(() => {
        if (!menuid && !seccionid) {
            if (!loading) {
                setOtherLoading(true);
            }
            listCategories().then((data) => {
                setCategories(data);
                setLoading(false);
                setOtherLoading(false);
            });
            setNombreP('menús');
            setNombreS('menú');
            setAddItem('/control-panel/addMenu');
            setThenGoTo('')
        } else if (menuid && !seccionid) {
            if (!loading) {
                setOtherLoading(true);
            }
            listSecciones(menuid).then((data) => {
                setCategories(data);
                setLoading(false);
                setOtherLoading(false);
            });
            categoryActionDetails(menuid).then(data => setNombreP(data.category.nombre));
            setNombreS('sección');
            setAddItem(`/control-panel/addSection/${menuid}`);
            setThenGoTo(`${menuid}/`)
        } else if (seccionid) {
            if (!loading) {
                setOtherLoading(true);
            }
            categoryActionDetails(menuid).then(data => setNombreP(data.category.nombre));
            setLoading(false);
            setOtherLoading(false);
        }
    }, [menuid, seccionid])

    return (
        <>  
        { loading ? (
            <Loader />
        ) : ( 
            categories && !seccionid ? <ItemList isLoading={otherLoading} namePCat={nombreP} nameSCat={nombreS} itemsList={categories} deleteItems={ !menuid ? deleteCategoryAction : deleteSeccionAction } navigateAdd={addItem} listItems={!menuid ? listCategories : listSecciones } handleDrag={!menuid ? handleDragEndCategories : handleDragEndSecciones} editItem={!menuid ? '/control-panel/editMenu' : `/control-panel/editSection/${menuid}` } thenGoTo={thenGoTo} /> : <ItemListProducts seccionId={seccionid} nombreMenu={nombreP} />
        )}
        </>
    )

}


export default CategoryListContainer;

