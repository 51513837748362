import { useState, useEffect } from 'react';
import './SideBar.css';
import { Link } from 'react-router-dom';


const Sidebar = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isShrinkView, setIsShrinkView] = useState(false);
  const [isShrinkViewMobile, setIsShrinkViewMobile] = useState(false);

  const handleSidebarView = () => {
    setIsShrinkView(!isShrinkView);
  };

  const handleSidebarViewMobile = () => {
    setIsShrinkViewMobile(!isShrinkViewMobile);
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    if (screenWidth > 700) {
      setIsShrinkViewMobile(false);
      setIsShrinkView(true);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={`container-mobile${screenWidth < 800 && !isShrinkViewMobile ? "True" : ""}`}>
        <div className={`sidebar-container${isShrinkView ? " shrink" : ""}`}>
          <button
            className={`sidebar-viewButton${screenWidth < 800 ? '-close' : ''}`}
            type="button"
            aria-label={isShrinkView ? "Expand Sidebar" : "Shrink Sidebar"}
            title={isShrinkView ? "Expand" : "Shrink"}
            onClick={handleSidebarView}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left"
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
          </button>
          <div className="sidebar-wrapper">
            <button className={`sidebar-close button-close${!isShrinkViewMobile ? '-true' : ''}`} onClick={handleSidebarViewMobile}>Cerrar</button>
            <ul className="sidebar-list">
              <li className="sidebar-listItem active">
                <Link to={`/control-panel/`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='sidebar-listIcon'><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 64V416H224V160H64zm384 0H288V416H448V160z" /></svg>
                  <span className="sidebar-listItemText">Menús</span>
                </Link>
              </li>
              <li className="sidebar-listItem">
                <Link to={`/`} target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='sidebar-listIcon'><path d="M0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zm64 32v64c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32zM80 320c-13.3 0-24 10.7-24 24s10.7 24 24 24h56c13.3 0 24-10.7 24-24s-10.7-24-24-24H80zm136 0c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H216z" /></svg>
                  <span className="sidebar-listItemText">Ir al sitio web</span>
                </Link>
              </li>
            </ul>
            <div className="sidebar-profileSection">
              <img
                src="https://assets.codepen.io/3306515/i-know.jpg"
                width="40"
                height="40"
                alt="Administrador"
              />
              <span>Administrador</span>
            </div>
          </div>
        </div>
      </div>
      <button className={`open-sidebar${isShrinkViewMobile ? '-false' : ''}`} type="button" aria-label={isShrinkView ? "Expand Sidebar" : "Shrink Sidebar"} title={isShrinkView ? "Expand" : "Shrink"} onClick={handleSidebarViewMobile}>Abrir menú</button>
    </>
  );
};

export default Sidebar;