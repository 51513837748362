import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import "./Select.css";

const Select = ({ categories, handleClick }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (selected) => {
        setSelectedOption(selected);

        const selectedSection = categories
            .find((category) => category.nombre === selected.value);

        handleClick(selectedSection);
    };

    useEffect(() => {
        if (categories && categories.length !== 0) {
            const selectedCategory = categories.find(
                (category) => category.visibilidad === true
            );

            if (selectedCategory) {
                setSelectedOption({
                    value: selectedCategory.nombre,
                    label: selectedCategory.nombre,
                });
                handleClick(selectedCategory);
            }
        }
    }, [categories]);

    const options = categories
        .filter((category) => category.visibilidad)
        .map((category) => ({
            value: category.nombre,
            label: category.nombre,
        }));

    return (
        <div className="select_field">
            <ReactSelect
                id="slct"
                value={selectedOption}
                options={options}
                onChange={handleOptionChange}
                classNamePrefix="react-select"
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'rgb(205, 205, 205)',
                        primary: 'black',
                    },
                })}
                isSearchable={false}
            />
            <svg className="sprites">
                <symbol id="select-arrow-down" viewBox="0 0 10 6">
                    <polyline points="1 1 5 5 9 1" />
                </symbol>
            </svg>
        </div>
    );
};

export default Select;
