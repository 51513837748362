import './NotFound.css';


const NotFound = () => {
    return (
      <div className="notfound">
        <h1>Error 404</h1>
        <p>Lo sentimos, la página que estás buscando no se pudo encontrar.</p>
        <p>Vuelve a la <a href="/">página de inicio</a> del restaurante.</p>
      </div>
    );
};

export default NotFound;
