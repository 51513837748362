import Footer from '../Footer/Footer';
import './Contact.css';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";



const Contact = () => {

  return (
    <ParallaxProvider>
      <div className="contacto">
        <div className="contenedor-parallax">
          {window.innerWidth < 700
            ? <img src={`/media/private/local2.jpg`} className="contenedor-parallax__img contenedor-parallax__img-right" alt="" />
            : <ParallaxBanner style={{ height: '100%' }}>
              <ParallaxBannerLayer image={`/media/private/local2.jpg`} speed={-50} style={{ backgroundSize: 'contain', backgroundRepeat: "no-repeat", backgroundPosition: 'center' }} />
            </ParallaxBanner>
          }
        </div>
        <div className="container">
          <div className="contact">
            <div className="contact-info">
              <h2>Contacto y horarios</h2>
              <a href="https://goo.gl/maps/BtAnyV62QzYyrU2M6" target="_blank" rel="noopener noreferrer" className="location">
                <i className="fa-solid fa-location-dot" style={{ color: '#ff4949' }}></i>
                <p>San Martin 891, Mendoza, Argentina, 5500</p>
              </a>
              <div className="hours">
                <div className="schedule">
                  <p className='schedule-bold'>Lunes a viernes: </p>
                  <p>07:30 A.M. hasta 23:30 P.M.</p>
                </div>
                <div className="schedule">
                  <p className='schedule-bold'>Sábados: </p>
                  <p>08:00 A.M. hasta 00:00 P.M.</p>
                </div>
              </div>
              <div className="social-media">
                <a href="https://www.instagram.com/pukka_cafe.bistro/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram" style={{ color: '#E1306C' }}></i><p>@pukka_cafe.bistro</p></a>
                <a href="https://www.facebook.com/people/Pukka-Caf%C3%A9-bistr%C3%B3/100069104941261/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f" style={{ color: '#4267B2' }}></i><p>Pukka. Café bistró</p></a>
                <a href="https://api.whatsapp.com/send?phone=542617227779" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-whatsapp" style={{ color: '#25D366' }}></i><p>+54 9 261 744-7779</p></a>
                <a href="mailto:administracion@pukkacaferestobar.com.ar"><i className="fa-regular fa-envelope" style={{ color: '#001f91' }}></i><p>administracion@pukkacaferestobar.com.ar</p></a>
              </div>
            </div>
            <div className="contact-img">
              <img src={`/media/private/bar.jpg`} alt="" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </ParallaxProvider>
  )
}


export default Contact;