import './Footer.css';


const Footer = () => {

    return (
        <div className="footer">
            <div className="footer__social">
                <a href="https://www.instagram.com/pukka_cafe.bistro/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a>
                <a href="https://www.facebook.com/people/Pukka-Caf%C3%A9-bistr%C3%B3/100069104941261/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f"></i></a>
                <a href="https://api.whatsapp.com/send?phone=542617227779" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-whatsapp"></i></a>
            </div>
            <div className="footer__location">
                San Martin 891, Mendoza, Argentina, 5500
            </div>
            <div className="footer__copy">
                &copy;2023 Pukka
            </div>
        </div>
    )

}


export default Footer;