import Footer from "../Footer/Footer";
import "./Home.css";
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import ButtonUp from "../ButtonUp/ButtonUp";
import 'aos/dist/aos.css';


const Home = () => {

    return (
        <ParallaxProvider>
            <div className="home">
                <div className="contenedor-parallax">
                    { window.innerWidth < 700
                    ? <img src={'/media/private/local2.jpg'} className="contenedor-parallax__img contenedor-parallax__img-right" alt="" />
                    : <ParallaxBanner style={{ height: '100%' }}>
                        <ParallaxBannerLayer image={'/media/private/local2.jpg'} speed={-50} style={{ backgroundSize: 'contain', backgroundRepeat: "no-repeat", backgroundPosition: 'center' }} />
                    </ParallaxBanner>
                    }
                </div>
                <div className="container" id="container-down">
                    <div className="down">
                    <img src={'https://pukkacaferesto.com.ar/media/private/down.png'} alt="" />
                    </div>
                </div>
                <div className="container">
                    <div className="about-us">
                        <div className={`about-us__column${ window.innerWidth < 700 ? ' about-us__column-reverse' : '' }`}>
                            <img src={`/media/private/afuera.jpg`} alt="" />
                            <img src={`/media/private/plato.jpg`} alt="" />
                            <div className="about-us__column--element">
                                <span>UN LUGAR VIBRANTE</span>
                                <h3>Delicias variadas.<br />Menú ejecutivo diario.</h3>
                                <p>Nuestro Café Restó es un lugar en pleno funcionamiento que combina lo mejor de un restaurante, bar y cafetería. Con una amplia variedad de opciones para deleitar tu paladar, en nuestro establecimiento encontrarás un sinfín de sabores y experiencias culinarias. Además, ofrecemos un emocionante menú ejecutivo que se renueva a diario, garantizando una experiencia gastronómica única en cada visita.</p>
                            </div>
                        </div>
                        <div className="about-us__column">
                            <div className="about-us__column--element">
                                <span>NUESTRO NEGOCIO</span>
                                <h3>Hecho en Pukka.<br />Productos locales.<br />Deliciosamente auténtico.</h3>
                                <p>Pukka es un café Restó que abrió sus puertas en 2021, cumpliendo el sueño de uno de sus dueños desde los 22 años. Comenzamos como un lugar para llevar, pero poco a poco hemos crecido hasta convertirnos en el establecimiento que somos hoy en día. Nuestro objetivo principal es brindar a las personas calidez y excelencia en la atención, así como opciones accesibles en nuestro menú. Seleccionamos cuidadosamente los mejores productos, desde su elección para su elaboración, hasta el momento en que llegan a las mesas, presentados en platos deliciosamente terminados. Nos enorgullece apoyar a productores locales y ofrecer una selección de calidad superior a nuestros clientes.</p>
                            </div>
                            <img src={`/media/private/pared.jpg`} alt="" />
                            <img src={`/media/private/brunch.jpg`} alt="" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="reservation">
                        <div className="reservation__img">
                            <img src={`/media/private/lomos.png`} alt="" />
                            <div className="reservation__text">
                                <span>¿Quieres hacer una reserva?</span>
                                <a href="https://api.whatsapp.com/send?phone=542617227779&text=Me%20gustar%C3%ADa%20hacer%20una%20reserva..." target="_blank" className="btn-typical" rel="noopener noreferrer" >Contáctanos</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="opinions">
                        <div className="opinion">
                            <p>Comentario real de cliente</p>
                            <h2>"¡Fascinada con los sorrentinos de espinaca con relleno de calabaza miel y salsa de champiñones! ¡Exquisitos! Muy buena atención por parte de las chicas y muy bien decorado además de aclimatado el lugar. Muy agradable para almorzar."</h2>
                        </div>
                        <div className="opinion">
                            <p>Comentario real de Local Guide</p>
                            <h2>"¡¡Hermoso lugar!! Muy buena opción para ir a tomar un café y comer rico. Nos atendieron súper bien. También tuvimos la posibilidad de hablar con el dueño, quien fue muy amable y nos contó un poco de que se trataba el lugar. ¡Lo recomiendo!"</h2>
                        </div>
                    </div>
                </div>
                <div className="contenedor-parallax">
                    { window.innerWidth < 700
                    ? <img src={`/media/private/fondo-letras.jpg`} className="contenedor-parallax__img" alt="" />
                    : <ParallaxBanner style={{ height: '100%', width: '100%' }}>
                        <ParallaxBannerLayer image={`/media/private/fondo-letras.jpg`} speed={-40} style={{ backgroundSize: 'contain', backgroundRepeat: "no-repeat", backgroundPosition: 'center' }} />
                    </ParallaxBanner>
                    }
                </div>
            </div>
            <Footer />
            <ButtonUp />
        </ParallaxProvider>
    );
};

export default Home;
